/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import r from "../../Color.js";
import { JSONSupport as e } from "../../core/JSONSupport.js";
import { clone as s } from "../../core/lang.js";
import { px2pt as t } from "../../core/screenUtils.js";
import { property as i } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import { subclass as p } from "../../core/accessorSupport/decorators/subclass.js";
import { colorAndTransparencyProperty as c, screenSizeProperty as n } from "../support/materialUtils.js";
let l = class extends e {
  constructor(o) {
    super(o), this.color = new r([0, 0, 0, 1]), this.extensionLength = 0, this.size = t(1);
  }
  clone() {}
  cloneProperties() {
    return {
      color: s(this.color),
      size: this.size,
      extensionLength: this.extensionLength
    };
  }
};
o([i({
  type: ["solid", "sketch"],
  readOnly: !0,
  json: {
    read: !0,
    write: {
      ignoreOrigin: !0
    }
  }
})], l.prototype, "type", void 0), o([i(c)], l.prototype, "color", void 0), o([i({
  ...n,
  json: {
    write: {
      overridePolicy: o => ({
        enabled: !!o
      })
    }
  }
})], l.prototype, "extensionLength", void 0), o([i(n)], l.prototype, "size", void 0), l = o([p("esri.symbols.edges.Edges3D")], l);
const m = l;
export { m as default };