/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import o from "../Color.js";
import { clone as r } from "../core/lang.js";
import { property as t } from "../core/accessorSupport/decorators/property.js";
import { Integer as s } from "../core/accessorSupport/ensureType.js";
import { enumeration as a } from "../core/accessorSupport/decorators/enumeration.js";
import { subclass as i } from "../core/accessorSupport/decorators/subclass.js";
import p from "./Symbol3DLayer.js";
var l;
let c = l = class extends p {
  constructor(e) {
    super(e), this.color = n.clone(), this.type = "water", this.waterbodySize = "medium", this.waveDirection = null, this.waveStrength = "moderate";
  }
  clone() {
    return new l({
      color: r(this.color),
      waterbodySize: this.waterbodySize,
      waveDirection: this.waveDirection,
      waveStrength: this.waveStrength
    });
  }
};
e([t({
  type: o,
  nonNullable: !0,
  json: {
    type: [s],
    write: (e, r, t) => r[t] = e.toArray(o.AlphaMode.UNLESS_OPAQUE),
    default: () => n.clone(),
    defaultEquals: e => e.toCss(!0) === n.toCss(!0)
  }
})], c.prototype, "color", void 0), e([a({
  Water: "water"
}, {
  readOnly: !0
})], c.prototype, "type", void 0), e([t({
  type: ["small", "medium", "large"],
  json: {
    write: !0,
    default: "medium"
  }
})], c.prototype, "waterbodySize", void 0), e([t({
  type: Number,
  json: {
    write: !0,
    default: null
  }
})], c.prototype, "waveDirection", void 0), e([t({
  type: ["calm", "rippled", "slight", "moderate"],
  json: {
    write: !0,
    default: "moderate"
  }
})], c.prototype, "waveStrength", void 0), c = l = e([i("esri.symbols.WaterSymbol3DLayer")], c);
const m = c,
  n = new o([0, 119, 190]);
export { m as default };