/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import { strict as t } from "../../core/jsonMap.js";
import { property as s } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { enumeration as r } from "../../core/accessorSupport/decorators/enumeration.js";
import { subclass as e } from "../../core/accessorSupport/decorators/subclass.js";
import d from "./LinePattern3D.js";
import { lineStyles as a } from "./lineStyles.js";
var p;
const h = t()({
  dash: "dash",
  "dash-dot": "dash-dot",
  "dash-dot-dot": "long-dash-dot-dot",
  dot: "dot",
  "long-dash": "long-dash",
  "long-dash-dot": "long-dash-dot",
  null: "none",
  "short-dash": "short-dash",
  "short-dash-dot": "short-dash-dot",
  "short-dash-dot-dot": "short-dash-dot-dot",
  "short-dot": "short-dot",
  solid: "solid"
});
let n = p = class extends d {
  constructor(o) {
    super(o), this.type = "style", this.style = "solid";
  }
  clone() {
    const o = {
      style: this.style
    };
    return new p(o);
  }
};
o([s({
  type: ["style"]
})], n.prototype, "type", void 0), o([r(h), s({
  type: a
})], n.prototype, "style", void 0), n = p = o([e("esri.symbols.patterns.LineStylePattern3D")], n);
const l = n;
export { l as default };