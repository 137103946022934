/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import e from "../core/Logger.js";
import { throwIfAborted as o } from "../core/promiseUtils.js";
import { property as r } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/RandomLCG.js";
import { enumeration as s } from "../core/accessorSupport/decorators/enumeration.js";
import { subclass as l } from "../core/accessorSupport/decorators/subclass.js";
import i from "../portal/Portal.js";
import { r as p, w as a } from "../chunks/persistableUrlUtils.js";
import n from "./Symbol.js";
import { Thumbnail as m } from "./support/Thumbnail.js";
var c;
let y = c = class extends n {
  constructor(t) {
    super(t), this.color = null, this.styleName = null, this.portal = null, this.styleUrl = null, this.thumbnail = null, this.name = null, this.type = "web-style";
  }
  get _fetchCacheKey() {
    const t = null != this.portal ? this.portal : i.getDefault(),
      e = t.user ? t.user.username : null;
    return `${this.styleName}:${this.styleUrl}:${this.name}:${e}:${t.url}`;
  }
  read(t, e) {
    this.portal = e?.portal, super.read(t, e);
  }
  clone() {
    return new c({
      name: this.name,
      styleUrl: this.styleUrl,
      styleName: this.styleName,
      portal: this.portal
    });
  }
  fetchSymbol(t) {
    return this._fetchSymbol("webRef", t);
  }
  fetchCIMSymbol(t) {
    return this._fetchSymbol("cimRef", t);
  }
  async _fetchSymbol(t, r) {
    const s = null != r ? r.cache : null,
      l = s ? this._fetchCacheKey : null;
    if (null != s) {
      const t = l && s.get(l);
      if (t) return t.clone();
    }
    const {
      resolveWebStyleSymbol: i
    } = await import("./support/webStyleSymbolUtils.js");
    o(r);
    const p = i(this, {
      portal: this.portal
    }, t, r);
    p.catch(t => {
      e.getLogger(this).error("#fetchSymbol()", "Failed to create symbol from style", t);
    });
    const a = await p;
    return "webRef" === t && "point-3d" === a.type || "cimRef" === t && "cim" === a.type ? (null != s && s.set(l, a.clone()), a) : null;
  }
};
t([r({
  json: {
    write: !1
  }
})], y.prototype, "color", void 0), t([r({
  type: String,
  json: {
    write: !0
  }
})], y.prototype, "styleName", void 0), t([r({
  type: i,
  json: {
    write: !1
  }
})], y.prototype, "portal", void 0), t([r({
  type: String,
  json: {
    read: p,
    write: a
  }
})], y.prototype, "styleUrl", void 0), t([r({
  type: m,
  json: {
    read: !1
  }
})], y.prototype, "thumbnail", void 0), t([r({
  type: String,
  json: {
    write: !0
  }
})], y.prototype, "name", void 0), t([s({
  styleSymbolReference: "web-style"
}, {
  readOnly: !0
})], y.prototype, "type", void 0), t([r()], y.prototype, "_fetchCacheKey", null), y = c = t([l("esri.symbols.WebStyleSymbol")], y);
const h = y;
export { h as default };