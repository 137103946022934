/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import t from "./LinePattern3D.js";
import e from "./LineStylePattern3D.js";
import r from "./Pattern3D.js";
import p from "./StylePattern3D.js";
const s = {
    types: {
      key: "type",
      base: r,
      typeMap: {
        style: p
      }
    },
    json: {
      write: !0
    }
  },
  o = {
    types: {
      key: "type",
      base: t,
      typeMap: {
        style: e
      }
    },
    json: {
      write: !0
    }
  };
export { o as symbol3dLinePatternProperty, s as symbol3dPatternProperty };