/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../chunks/tslib.es6.js";
import r from "../Color.js";
import { ClonableMixin as e } from "../core/Clonable.js";
import { JSONSupport as t } from "../core/JSONSupport.js";
import { property as s } from "../core/accessorSupport/decorators/property.js";
import { Integer as l } from "../core/accessorSupport/ensureType.js";
import "../core/RandomLCG.js";
import "../core/has.js";
import { subclass as p } from "../core/accessorSupport/decorators/subclass.js";
import { lineMarkerPlacements as i, lineMarkerStyles as c } from "./support/lineMarkers.js";
let n = class extends e(t) {
  constructor(o) {
    super(o), this.type = "style", this.placement = "begin-end", this.style = "arrow", this.color = null;
  }
  equals(o) {
    return null != o && o.placement === this.placement && o.style === this.style && (null == this.color && null == o.color || null != this.color && null != o.color && this.color.toJSON() === o.color.toJSON());
  }
};
o([s({
  type: ["style"],
  readOnly: !0,
  json: {
    read: !0,
    write: {
      ignoreOrigin: !0
    }
  }
})], n.prototype, "type", void 0), o([s({
  type: i,
  json: {
    default: "begin-end",
    write: !0
  }
})], n.prototype, "placement", void 0), o([s({
  type: c,
  json: {
    default: "arrow",
    write: !0
  }
})], n.prototype, "style", void 0), o([s({
  type: r,
  json: {
    type: [l],
    default: null,
    write: !0
  }
})], n.prototype, "color", void 0), n = o([p("esri.symbols.LineStyleMarker3D")], n);
const a = n;
export { a as default };