/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import "../../core/Logger.js";
import "../../core/has.js";
import "../../core/RandomLCG.js";
import "../../core/Error.js";
import { enumeration as r } from "../../core/accessorSupport/decorators/enumeration.js";
import { subclass as s } from "../../core/accessorSupport/decorators/subclass.js";
import { clone as e } from "../../core/libs/gl-matrix-2/factories/vec3f64.js";
import { Symbol3DMaterial as t } from "./Symbol3DMaterial.js";
var i;
let c = i = class extends t {
  constructor(o) {
    super(o), this.colorMixMode = null;
  }
  clone() {
    const o = {
      color: this.color?.clone() ?? null,
      emissiveFactor: this.emissiveFactor ? e(this.emissiveFactor) : null,
      colorMixMode: this.colorMixMode
    };
    return new i(o);
  }
};
o([r({
  multiply: "multiply",
  replace: "replace",
  tint: "tint"
})], c.prototype, "colorMixMode", void 0), c = i = o([s("esri.symbols.support.Symbol3DFillMaterial")], c);
export { c as Symbol3DFillMaterial };