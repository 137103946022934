/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import "../../core/Logger.js";
import "../../core/has.js";
import "../../core/RandomLCG.js";
import "../../core/Error.js";
import { enumeration as r } from "../../core/accessorSupport/decorators/enumeration.js";
import { subclass as s } from "../../core/accessorSupport/decorators/subclass.js";
import e from "./Edges3D.js";
var t;
let c = t = class extends e {
  constructor(o) {
    super(o), this.type = "solid";
  }
  clone() {
    return new t(this.cloneProperties());
  }
};
o([r({
  solid: "solid"
}, {
  readOnly: !0
})], c.prototype, "type", void 0), c = t = o([s("esri.symbols.edges.SolidEdges3D")], c);
const i = c;
export { i as default };