/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as r } from "../../chunks/tslib.es6.js";
import { JSONSupport as o } from "../../core/JSONSupport.js";
import { property as e } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as s } from "../../core/accessorSupport/decorators/subclass.js";
let t = class extends o {
  constructor(r) {
    super(r), this.type = "style";
  }
  clone() {
    throw new Error("Subclasses of Pattern3D should implement their own clone method.");
  }
};
r([e({
  type: ["style"],
  readOnly: !0,
  json: {
    read: !0,
    write: {
      ignoreOrigin: !0
    }
  }
})], t.prototype, "type", void 0), t = r([s("esri.symbols.patterns.Pattern3D")], t);
const p = t;
export { p as default };