/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import { property as o } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { enumeration as e } from "../core/accessorSupport/decorators/enumeration.js";
import { subclass as l } from "../core/accessorSupport/decorators/subclass.js";
import r from "./Symbol3DLayer.js";
import { symbol3dEdgesProperty as s } from "./edges/utils.js";
import i from "./patterns/LineStylePattern3D.js";
import n from "./patterns/StylePattern3D.js";
import { symbol3dPatternProperty as p } from "./patterns/utils.js";
import { white as a, transparentWhite as u } from "./support/colors.js";
import { Symbol3DFillMaterial as m } from "./support/Symbol3DFillMaterial.js";
import { Symbol3DOutline as c } from "./support/Symbol3DOutline.js";
var y;
let d = y = class extends r {
  constructor(t) {
    super(t), this.type = "fill", this.material = null, this.pattern = null, this.castShadows = !0, this.outline = null, this.edges = null;
  }
  clone() {
    const t = {
      edges: null != this.edges ? this.edges.clone() : null,
      enabled: this.enabled,
      material: null != this.material ? this.material.clone() : null,
      pattern: null != this.pattern ? this.pattern.clone() : null,
      castShadows: this.castShadows,
      outline: null != this.outline ? this.outline.clone() : null
    };
    return new y(t);
  }
  static fromSimpleFillSymbol(t) {
    const o = t.outline && t.outline.style && "solid" !== t.outline.style ? new i({
        style: t.outline.style
      }) : null,
      e = {
        size: t.outline?.width ?? 0,
        color: (t.outline?.color ?? a).clone(),
        pattern: o
      };
    return o && t.outline?.cap && (e.patternCap = t.outline.cap), new y({
      material: new m({
        color: (t.color ?? u).clone()
      }),
      pattern: t.style && "solid" !== t.style ? new n({
        style: t.style
      }) : null,
      outline: e
    });
  }
};
t([e({
  Fill: "fill"
}, {
  readOnly: !0
})], d.prototype, "type", void 0), t([o({
  type: m,
  json: {
    write: !0
  }
})], d.prototype, "material", void 0), t([o(p)], d.prototype, "pattern", void 0), t([o({
  type: Boolean,
  nonNullable: !0,
  json: {
    write: !0,
    default: !0
  }
})], d.prototype, "castShadows", void 0), t([o({
  type: c,
  json: {
    write: !0
  }
})], d.prototype, "outline", void 0), t([o(s)], d.prototype, "edges", void 0), d = y = t([l("esri.symbols.FillSymbol3DLayer")], d);
const h = d;
export { h as default };