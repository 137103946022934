/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../chunks/tslib.es6.js";
import r from "../core/Collection.js";
import { clone as s } from "../core/lang.js";
import { property as e } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import { enumeration as t } from "../core/accessorSupport/decorators/enumeration.js";
import { subclass as m } from "../core/accessorSupport/decorators/subclass.js";
import l from "./FillSymbol3DLayer.js";
import i from "./Symbol3D.js";
var p;
const y = r.ofType({
  base: null,
  key: "type",
  typeMap: {
    fill: l
  }
});
let a = p = class extends i {
  constructor(o) {
    super(o), this.symbolLayers = new y(), this.type = "mesh-3d";
  }
  clone() {
    return new p({
      styleOrigin: s(this.styleOrigin),
      symbolLayers: s(this.symbolLayers),
      thumbnail: s(this.thumbnail)
    });
  }
  static fromSimpleFillSymbol(o) {
    return new p({
      symbolLayers: new r([l.fromSimpleFillSymbol(o)])
    });
  }
};
o([e({
  type: y
})], a.prototype, "symbolLayers", void 0), o([t({
  MeshSymbol3D: "mesh-3d"
}, {
  readOnly: !0
})], a.prototype, "type", void 0), a = p = o([m("esri.symbols.MeshSymbol3D")], a);
const c = a;
export { c as default };