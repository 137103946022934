/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../chunks/tslib.es6.js";
import e from "../core/Collection.js";
import { deprecated as r } from "../core/deprecate.js";
import { clone as s } from "../core/lang.js";
import t from "../core/Logger.js";
import { on as l, sync as y } from "../core/reactiveUtils.js";
import { property as m } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import { enumeration as a } from "../core/accessorSupport/decorators/enumeration.js";
import { subclass as i } from "../core/accessorSupport/decorators/subclass.js";
import n from "./ExtrudeSymbol3DLayer.js";
import p from "./FillSymbol3DLayer.js";
import b from "./IconSymbol3DLayer.js";
import c from "./LineSymbol3DLayer.js";
import L from "./ObjectSymbol3DLayer.js";
import f from "./Symbol3D.js";
import S from "./TextSymbol3DLayer.js";
import d from "./WaterSymbol3DLayer.js";
var u;
const j = e.ofType({
  base: null,
  key: "type",
  typeMap: {
    extrude: n,
    fill: p,
    icon: b,
    line: c,
    object: L,
    text: S,
    water: d
  }
});
let g = u = class extends f {
  constructor(o) {
    super(o), this.symbolLayers = new j(), this.type = "polygon-3d";
  }
  initialize() {
    const o = o => {
      "line" === o.type && r(t.getLogger(this), "LineSymbol3DLayer can not be used as a SymbolLayer with a PolygonSymbol3D symbol anymore.", {
        replacement: "Use FillSymbol3DLayer.outline instead.",
        version: "4.28"
      }), "text" === o.type && r(t.getLogger(this), "TextSymbol3DLayer can not be used as a SymbolLayer with a PolygonSymbol3D symbol anymore.", {
        replacement: "Use Labels instead.",
        version: "4.28"
      });
    };
    for (const e of this.symbolLayers) o(e);
    this.addHandles(l(() => this.symbolLayers, "after-add", ({
      item: e
    }) => o(e), y));
  }
  clone() {
    return new u({
      styleOrigin: s(this.styleOrigin),
      symbolLayers: s(this.symbolLayers),
      thumbnail: s(this.thumbnail)
    });
  }
  static fromJSON(o) {
    const e = new u();
    if (e.read(o), 2 === e.symbolLayers.length && "fill" === e.symbolLayers.at(0).type && "line" === e.symbolLayers.at(1).type) {
      const r = e.symbolLayers.at(0),
        s = e.symbolLayers.at(1);
      !s.enabled || o.symbolLayers?.[1] && !1 === o.symbolLayers[1].enable || (r.outline = {
        size: s.size,
        color: null != s.material ? s.material.color : null
      }), e.symbolLayers.removeAt(1);
    }
    return e;
  }
  static fromSimpleFillSymbol(o) {
    return new u({
      symbolLayers: new e([p.fromSimpleFillSymbol(o)])
    });
  }
};
o([m({
  type: j,
  json: {
    write: !0
  }
})], g.prototype, "symbolLayers", void 0), o([a({
  PolygonSymbol3D: "polygon-3d"
}, {
  readOnly: !0
})], g.prototype, "type", void 0), g = u = o([i("esri.symbols.PolygonSymbol3D")], g);
const h = g;
export { h as default };