/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as r } from "../../chunks/tslib.es6.js";
import { strict as e } from "../../core/jsonMap.js";
import { JSONSupport as o } from "../../core/JSONSupport.js";
import { property as s } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { enumeration as t } from "../../core/accessorSupport/decorators/enumeration.js";
import { subclass as i } from "../../core/accessorSupport/decorators/subclass.js";
import { r as p, w as c } from "../../chunks/persistableUrlUtils.js";
var m;
const n = e()({
  sphere: "sphere",
  cylinder: "cylinder",
  cube: "cube",
  cone: "cone",
  diamond: "diamond",
  tetrahedron: "tetrahedron",
  invertedCone: "inverted-cone"
});
let a = m = class extends o {
  clone() {
    return new m({
      href: this.href,
      primitive: this.primitive
    });
  }
};
r([s({
  type: String,
  json: {
    read: p,
    write: c
  }
})], a.prototype, "href", void 0), r([t(n)], a.prototype, "primitive", void 0), a = m = r([i("esri.symbols.support.ObjectSymbol3DLayerResource")], a);
const d = "sphere";
export { a as ObjectSymbol3DLayerResource, d as defaultPrimitive };