/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as r } from "../../chunks/tslib.es6.js";
import { JSONSupport as o } from "../../core/JSONSupport.js";
import { property as e } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as t } from "../../core/accessorSupport/decorators/subclass.js";
import { screenSizeProperty as s } from "./materialUtils.js";
var n;
let i = n = class extends o {
  constructor(r) {
    super(r), this.screenLength = 0, this.minWorldLength = 0, this.maxWorldLength = null;
  }
  clone() {
    return new n({
      screenLength: this.screenLength,
      minWorldLength: this.minWorldLength,
      maxWorldLength: this.maxWorldLength
    });
  }
};
r([e(s)], i.prototype, "screenLength", void 0), r([e({
  type: Number,
  nonNullable: !0,
  json: {
    write: !0,
    default: 0
  }
})], i.prototype, "minWorldLength", void 0), r([e({
  type: Number,
  json: {
    write: !0
  }
})], i.prototype, "maxWorldLength", void 0), i = n = r([t("esri.symbols.support.Symbol3DVerticalOffset")], i);
const p = i;
export { p as default };