/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import { JSONSupport as r } from "../../core/JSONSupport.js";
import { property as s } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as t } from "../../core/accessorSupport/decorators/subclass.js";
import { clone as e } from "../../core/libs/gl-matrix-2/factories/vec3f64.js";
import { colorAndTransparencyProperty as i } from "./materialUtils.js";
var c;
let m = c = class extends r {
  constructor(o) {
    super(o), this.color = null, this.emissiveFactor = null;
  }
  clone() {
    const o = {
      color: this.color?.clone() ?? null,
      emissiveFactor: this.emissiveFactor ? e(this.emissiveFactor) : null
    };
    return new c(o);
  }
};
o([s(i)], m.prototype, "color", void 0), o([s({
  type: [Number],
  json: {
    write: !1
  }
})], m.prototype, "emissiveFactor", void 0), m = c = o([t("esri.symbols.support.Symbol3DMaterial")], m);
export { m as Symbol3DMaterial };