/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import { JSONSupport as r } from "../../core/JSONSupport.js";
import { property as s } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as e } from "../../core/accessorSupport/decorators/subclass.js";
let t = class extends r {
  constructor(o) {
    super(o), this.visible = !0;
  }
  clone() {
    throw new Error("Subclasses of Callout3D should implement their own clone method.");
  }
};
o([s({
  type: ["line"],
  constructOnly: !0,
  json: {
    read: !1,
    write: {
      ignoreOrigin: !0
    }
  }
})], t.prototype, "type", void 0), o([s({
  readOnly: !0
})], t.prototype, "visible", void 0), t = o([e("esri.symbols.callouts.Callout3D")], t);
const c = t;
export { c as default };