/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { ensureOneOfType as e } from "./core/accessorSupport/ensureType.js";
import { createTypeReader as o } from "./core/accessorSupport/extensions/serializableProperty/reader.js";
import l from "./symbols/CIMSymbol.js";
export { default as ExtrudeSymbol3DLayer } from "./symbols/ExtrudeSymbol3DLayer.js";
export { default as BaseFillSymbol } from "./symbols/FillSymbol.js";
export { default as FillSymbol3DLayer } from "./symbols/FillSymbol3DLayer.js";
export { default as Font } from "./symbols/Font.js";
export { default as IconSymbol3DLayer } from "./symbols/IconSymbol3DLayer.js";
import r from "./symbols/LabelSymbol3D.js";
import s from "./symbols/LineSymbol3D.js";
export { default as LineSymbol3DLayer } from "./symbols/LineSymbol3DLayer.js";
export { default as BaseMarkerSymbol } from "./symbols/MarkerSymbol.js";
import m from "./symbols/MeshSymbol3D.js";
export { default as ObjectSymbol3DLayer } from "./symbols/ObjectSymbol3DLayer.js";
export { default as PathSymbol3DLayer } from "./symbols/PathSymbol3DLayer.js";
import t from "./symbols/PictureFillSymbol.js";
import y from "./symbols/PictureMarkerSymbol.js";
import a from "./symbols/PointSymbol3D.js";
import p from "./symbols/PolygonSymbol3D.js";
import b from "./symbols/SimpleFillSymbol.js";
import i from "./symbols/SimpleLineSymbol.js";
import n from "./symbols/SimpleMarkerSymbol.js";
import f from "./symbols/Symbol.js";
export { default as BaseSymbol3D } from "./symbols/Symbol3D.js";
export { default as BaseSymbol3DLayer } from "./symbols/Symbol3DLayer.js";
import c from "./symbols/TextSymbol.js";
export { default as TextSymbol3DLayer } from "./symbols/TextSymbol3DLayer.js";
export { default as WaterSymbol3DLayer } from "./symbols/WaterSymbol3DLayer.js";
import d from "./symbols/WebStyleSymbol.js";
export { default as LineCallout3D } from "./symbols/callouts/LineCallout3D.js";
export { default as LineCallout3DBorder } from "./symbols/callouts/LineCallout3DBorder.js";
function u(e) {
  return e instanceof f;
}
function S(e) {
  if (!e) return !1;
  switch (e.type) {
    case "picture-fill":
    case "picture-marker":
    case "simple-fill":
    case "simple-line":
    case "simple-marker":
    case "text":
    case "cim":
      return !0;
    default:
      return !1;
  }
}
function x(e) {
  if (!e) return !1;
  switch (e.type) {
    case "label-3d":
    case "line-3d":
    case "mesh-3d":
    case "point-3d":
    case "polygon-3d":
      return !0;
    default:
      return !1;
  }
}
const j = {
    base: f,
    key: "type",
    typeMap: {
      "simple-fill": b,
      "picture-fill": t,
      "picture-marker": y,
      "simple-line": i,
      "simple-marker": n,
      text: c,
      "label-3d": r,
      "line-3d": s,
      "mesh-3d": m,
      "point-3d": a,
      "polygon-3d": p,
      "web-style": d,
      cim: l
    },
    errorContext: "symbol"
  },
  D = {
    base: f,
    key: "type",
    typeMap: {
      "picture-marker": y,
      "simple-marker": n,
      "point-3d": a,
      cim: l
    },
    errorContext: "symbol"
  },
  L = {
    base: f,
    key: "type",
    typeMap: {
      "simple-line": i,
      "line-3d": s,
      cim: l
    },
    errorContext: "symbol"
  },
  k = {
    base: f,
    key: "type",
    typeMap: {
      "simple-fill": b,
      "picture-fill": t,
      "polygon-3d": p,
      cim: l
    },
    errorContext: "symbol"
  },
  M = {
    base: f,
    key: "type",
    typeMap: {
      "picture-marker": y,
      "simple-marker": n,
      text: c,
      "web-style": d,
      cim: l
    },
    errorContext: "symbol"
  },
  C = o({
    types: j
  }),
  h = {
    base: f,
    key: "type",
    typeMap: {
      "simple-fill": b,
      "picture-fill": t,
      "picture-marker": y,
      "simple-line": i,
      "simple-marker": n,
      text: c,
      "line-3d": s,
      "mesh-3d": m,
      "point-3d": a,
      "polygon-3d": p,
      "web-style": d,
      cim: l
    },
    errorContext: "symbol"
  },
  F = {
    base: f,
    key: "type",
    typeMap: {
      text: c,
      "label-3d": r
    },
    errorContext: "symbol"
  },
  g = {
    base: f,
    key: "type",
    typeMap: {
      "label-3d": r,
      "line-3d": s,
      "mesh-3d": m,
      "point-3d": a,
      "polygon-3d": p,
      "web-style": d
    },
    errorContext: "symbol"
  },
  w = {
    base: f,
    key: "type",
    typeMap: {
      "line-3d": s,
      "mesh-3d": m,
      "point-3d": a,
      "polygon-3d": p,
      "web-style": d,
      cim: l
    },
    errorContext: "symbol"
  },
  P = {
    base: f,
    key: "type",
    typeMap: {
      "label-3d": r
    },
    errorContext: "symbol"
  },
  B = e(j);
export { f as BaseSymbol, l as CIMSymbol, r as LabelSymbol3D, s as LineSymbol3D, m as MeshSymbol3D, t as PictureFillSymbol, y as PictureMarkerSymbol, a as PointSymbol3D, p as PolygonSymbol3D, b as SimpleFillSymbol, i as SimpleLineSymbol, n as SimpleMarkerSymbol, c as TextSymbol, d as WebStyleSymbol, B as ensureType, u as isSymbol, S as isSymbol2D, x as isSymbol3D, L as lineSymbolTypes, D as pointSymbolTypes, k as polygonSymbolTypes, C as readSymbol, j as symbolTypes, g as symbolTypes3D, M as symbolTypesCluster, F as symbolTypesLabel, P as symbolTypesLabel3D, h as symbolTypesRenderer, w as symbolTypesRenderer3D };