/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import { property as o } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { enumeration as e } from "../core/accessorSupport/decorators/enumeration.js";
import { reader as r } from "../core/accessorSupport/decorators/reader.js";
import { subclass as i } from "../core/accessorSupport/decorators/subclass.js";
import s from "./Symbol3DLayer.js";
import { Symbol3DMaterial as a } from "./support/Symbol3DMaterial.js";
import { lineJoins as p, pathCaps as l } from "./support/symbolLayerUtils3D.js";
var h;
let n = h = class extends s {
  constructor(t) {
    super(t), this.material = null, this.castShadows = !0, this.type = "path", this.profile = "circle", this.join = "miter", this.cap = "butt", this.width = void 0, this.height = void 0, this.anchor = "center", this.profileRotation = "all";
  }
  readWidth(t, o) {
    return null != t ? t : null == o.height && null != o.size ? o.size : void 0;
  }
  readHeight(t, o) {
    return null != t ? t : null == o.width && null != o.size ? o.size : void 0;
  }
  clone() {
    return new h({
      enabled: this.enabled,
      material: null != this.material ? this.material.clone() : null,
      castShadows: this.castShadows,
      profile: this.profile,
      join: this.join,
      cap: this.cap,
      width: this.width,
      height: this.height,
      profileRotation: this.profileRotation,
      anchor: this.anchor
    });
  }
};
t([o({
  type: a,
  json: {
    write: !0
  }
})], n.prototype, "material", void 0), t([o({
  type: Boolean,
  nonNullable: !0,
  json: {
    write: !0,
    default: !0
  }
})], n.prototype, "castShadows", void 0), t([e({
  Path: "path"
}, {
  readOnly: !0
})], n.prototype, "type", void 0), t([o({
  type: ["circle", "quad"],
  json: {
    write: !0,
    default: "circle"
  }
})], n.prototype, "profile", void 0), t([o({
  type: p,
  json: {
    write: !0,
    default: "miter"
  }
})], n.prototype, "join", void 0), t([o({
  type: l,
  json: {
    write: !0,
    default: "butt"
  }
})], n.prototype, "cap", void 0), t([o({
  type: Number,
  json: {
    write: {
      enabled: !0,
      target: {
        width: {
          type: Number
        },
        size: {
          type: Number
        }
      }
    }
  }
})], n.prototype, "width", void 0), t([r("width", ["width", "size", "height"])], n.prototype, "readWidth", null), t([o({
  type: Number,
  json: {
    write: !0
  }
})], n.prototype, "height", void 0), t([r("height", ["height", "size", "width"])], n.prototype, "readHeight", null), t([o({
  type: ["center", "bottom", "top"],
  json: {
    write: !0,
    default: "center"
  }
})], n.prototype, "anchor", void 0), t([o({
  type: ["heading", "all"],
  json: {
    write: !0,
    default: "all"
  }
})], n.prototype, "profileRotation", void 0), n = h = t([i("esri.symbols.PathSymbol3DLayer")], n);
const d = n;
export { d as default };