/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import { property as s } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as r } from "../../core/accessorSupport/decorators/subclass.js";
import o from "./Pattern3D.js";
import e from "./styles.js";
var p;
let c = p = class extends o {
  constructor(t) {
    super(t), this.type = "style", this.style = "solid";
  }
  clone() {
    return new p({
      style: this.style
    });
  }
};
t([s({
  type: ["style"]
})], c.prototype, "type", void 0), t([s({
  type: e,
  json: {
    read: !0,
    write: !0
  }
})], c.prototype, "style", void 0), c = p = t([r("esri.symbols.patterns.StylePattern3D")], c);
const i = c;
export { i as default };